body {
    overflow: unset;
    margin: 0;
    padding: 0;
    background-color: #222;
    box-sizing: border-box;
}

.App {
    padding: 0 15px;
}

.App-header {
    background-color: #222;
    display: flex;
    align-items: center;
    min-height: 6vh;
}

.App-header h1 {
    color: white;
    margin: 0;
}

.App-intro {
    font-size: large;
}

div#user {
    display: flex;
    flex-direction: row-reverse;
}

#user img {
    float: right;
}

#editor {
    padding-left: 0;
}

@media (min-width: 1200px) {
    #editor {
        border-right: 0;
    }
    .App {
        padding: 0;
    }
    #interface {
        padding-right: 0;
    }
}

#scene {
    height: 94vh;
    border-left: #222 1px solid;
    border-top: #222 1px solid;
}

a-scene {
    height: 100% !important;
}

a-scene[aframe-inspector-removed-embedded] {
    width: 100vh;
    left: -35%;
}

.ace-github .ace_print-margin {
    background: #fff !important;
}

th:nth-child(1),
td:nth-child(1) {
    color: #266d90;
    padding: 10px;
}

/* inspector fixes */

#sidebar,
.components {
    width: 400px !important;
}

.components .row {
    margin-left: 5px;
}

.toggle-edit {
    left: 150px !important;
    position: absolute !important;
    width: 150px !important;
}

body.aframe-inspector-opened .toggle-edit {
    left: 0 !important;
    top: 0 !important;
}

/* animations */

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.delete-btn {
    float: right;
    margin-top: 4px !important;
}

#interface {
    border-right: 0;
}

.spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */

.spinner:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */

.spinner:not(:required) {
    /* hide "spinner..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.cube1,
.cube2,
cube3 {
    background-color: #222;
    width: 35px;
    height: 35px;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
    animation: sk-cubemove 3.8s infinite ease-in-out;
}

.cube2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
    25% {
        -webkit-transform: translateX(42px) rotate(-90deg) scale(4.5)
    }
    50% {
        -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg)
    }
    75% {
        -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(4.5)
    }
    100% {
        -webkit-transform: rotate(-360deg)
    }
}

@keyframes sk-cubemove {
    25% {
        transform: translateX(42px) rotate(-90deg) scale(0.5);
        -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    }
    50% {
        transform: translateX(42px) translateY(42px) rotate(-179deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    }
    50.1% {
        transform: translateX(42px) translateY(42px) rotate(-180deg);
        -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    }
    75% {
        transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
        -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    }
    100% {
        transform: rotate(-360deg);
        -webkit-transform: rotate(-360deg);
    }
}

.to-delete {
    filter: grayscale();
}

div.react-console-welcome.error {
    color: red !important;
}

div.react-console-welcome {
    font-size: 14pt;
    border-bottom: 1px solid;
}

div.side-drawer>div {
    padding: 10px;
    width: 85%;
    padding-top: 3em;
    overflow: visible;
}

@media (min-width: 450px) {
    div.side-drawer>div {
        padding: 10px;
        width: 400px;
        padding-top: 3em;
        overflow: visible;
    }
    .sidebar-btn {
        margin-bottom: 10px !important;
        justify-content: left !important;
        padding-left: 6em !important;
    }
}

@media (min-width: 1200px) {
    div.side-drawer>div {
        padding: 10px;
        width: 400px;
        padding-top: 3em;
        overflow: visible;
    }
}

.sidebar-btn {
    margin-bottom: 10px !important;
    justify-content: left !important;
}

.ref-ex-btn {
    margin-bottom: 10px !important;
    justify-content: left !important;
}

.MuiTabs-root-76 {
    overflow: unset !important;
}

#ace-editor {
    border-bottom: 1px solid;
    border-top: 1px solid;
}

.guided div#ace-editor {
    height: 69.5vh !important;
}

.collection div#ace-editor {
    height: 80vh !important;
}

div#confirmation-modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    height: inherit;
    width: inherit;
    border: 1px solid;
    padding: 1em;
    border-radius: 5px;
    background: #eee;
}

div#reference-tabs {
    overflow: unset !important;
    min-height: unset !important;
}

.sidebar-btn .material-icons {
    margin: 0 .7em;
}

a.sidebar-btn:hover {
    color: #fff;
}

span.user-name {
    color: #fff;
    font-size: 90%;
    margin-left: 20px;
}

div#reference-drawer>div {
    width: 67%;
}
.refExample {
    margin-right: 10px;
}

/* Overwrites default A-Frame behavior for embedded
 * buttons to behave as if scene was located within
 * an iframe */
.a-enter-vr {
    position: absolute;
    bottom:20px !important;
    right:20px !important;
    z-index: 1 !important;
}

.a-enter-ar {
    position: absolute;
    bottom:20px !important;
    right:80px !important;
    z-index: 1 !important;
}

.geometry {
    transform: scale(1.2);
}

.color-change {
    -webkit-animation: colorchange 8s infinite linear;
    transform: scale(1.5);
}

@-webkit-keyframes colorchange {
    25% {
        color: red;
    }
    50% {
        color: green;
    }
    75% {
        color: blue;
    }
    100% {
        color: black;
    }
}

.animation-ref {
    animation: animate infinite 4s linear;
}

@keyframes animate {
    25% {
        transform: rotate(0deg) scale(.85);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(180deg) scale(1.15);
    }
    100% {
        transform: rotate(360deg);
    }
}

span.bool {
    color: red;
}

span.number {
    color: blue;
}

span.string {
    color: green;
}

span.group{
    color: purple;
}

span.data{
    color: orange;
}

span.array {
    color: black;
    font-weight: bolder;
}

div#projectDrawer>div {
    width: 80%;
}

footer {
    background-color: #222;
    color: #fff;
    margin-top: 20px;
}

footer a {
    color: #fff;
}

footer a:hover {
    color: #999;
}

@media (max-width: 370px) {
    .header-btn {
        width: 30px !important;
    }
    .scene-btns {
        position: absolute;
        z-index: 200;
        display: flex;
        bottom: 4px;
        right: 18px;
    }
}
