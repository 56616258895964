#reference-page {
    background: #fff;
    min-height: 94vh;
    width: 100%
}

#ref-ex {
    padding: 10px !important;
    background: #fff !important;
}

.ref-ex-edit div#ace-editor {
    height: 69.5vh !important;
}

#ref-ex p {
    height: 12vh;
    margin: 0;
    overflow: auto;
}

#ref-ex fieldset {
    height: 16vh;
    margin: 0;
}

.ref-ex-btn .material-icons {
    margin: 0 .3em;
}

a.ref-ex-btn:hover {
    color: #fff;
}

.ref-ex-btn {
    margin-top: 1em !important;
    margin-bottom: 10px !important;
    justify-content: center !important;
    padding-left: 1em !important;
    width: 100% !important;
    text-align: center !important;
}

#suggested-course {
    padding-right: 2em;
}