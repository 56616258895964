#collection-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: scroll;
}

#select-project {
    padding: 10px;
    background: #fff !important;
}

.collection-icon {
    margin-right: 3px;
}