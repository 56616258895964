p#info-description {
    max-height: 70vh;
    overflow: auto;
}

#project-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: auto !important;
}

#scene-card:hover {
    background-color: rgb(206, 206, 192);
}