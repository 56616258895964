.course-listing p {
    height: 6em;
    overflow: auto;
    color: #333;
}

.course-listing a {
    text-decoration: none;
}


.course-listing a:hover h4 {
    text-decoration: underline;
    color: blue;
}

div#courses {
    max-height: 70vh;
    overflow: auto;
}

#lesson {
    padding: 10px;
    background: #fff !important;
}

#lesson p {
    height: 12vh;
    margin: 0;
    overflow: auto;
}

#lesson fieldset {
    height: 16vh;
    margin: 0;
}