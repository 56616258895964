.keyboard-shortcut {
    width:auto;
}

.title {
    font-size:large;
}

.shortcut {
    font-size:xx-small;
}

.shortcut-button {
    height: 34px;
    width: 80px;
}

.right {
    float: left;
    width: 310px;
    padding: 10px;
}

.note {
    margin-left: 1%;
}

.font-button {
    height:34px;
    width:80px;
}

.font {
    display: inline-block;
    margin-left: 4px;
}

.whole-keyboard {
    display: inline-block;
    margin-left: 1px;
}

.select {
    color: white !important;
    margin-left: 5px;
}