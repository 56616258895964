.chrome-picker {
    width: 100% !important;
    left: 0;
    top: 0;
}

#color-popover {
    position: absolute;
    z-index: 2;
    left: 10%;
    width: 80%;
}

#color-cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: #fff;
}

.reset-button-group {
    display: flex;
    align-items: center; 
    justify-content: center;
    background-color: #c2aa4b;
    
}

.reset-button {
    background-color: transparent;
    border: 0;
    color: #c2aa4b;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    line-height: 1;
    margin-top: 1.5rem;
    width: 100%; 
}